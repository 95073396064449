// import { __changeTheme } from '../../composables'
import React , { useRef, useState } from 'react'
import { langList, setLangObject, getLangObject } from '../../../i18n/langUtil'
import { useViewport } from '../../../hooks/useViewPort';
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Drawer, Popover, Modal } from 'antd'
import './index.less'

interface navItem {
    title: string,
    label: string,
    index: string,
    link?: string,
    type?: 'link' | 'outLink' | 'action',
    width?: string,
    action?: () => void,
    child: {
        index: string,
        title: string,
        link: string,
        type?: string

    }[]
}

const Header:React.FC = () => {
  const { t } = useTranslation()
  const [mobileNavsDrawer, setMobileNavsDrawer] = useState(false)
  const [airdropModal, setAirdropModal] = useState(false)
  const {pathname} = useLocation()
  const headerNav:navItem[] = [
    {
      title: t('nav.Home'),
      label: "home",
      index: "1",
      link: '/',
      type: 'link',
      child: []
    },
    {
        title: 'PolarFighters',
        label: "PolarFighters",
        index: "2",
        link: '/polarfighters',
        type: 'link',
        child: []
    },
    {
      title: 'About Polarise',
      label: "blog",
      index: "3",
      type: 'outLink',
      link: 'https://blog.pawnfi.com/',
      child: [
        // {
        //   index: "2-1",
        //   title: 'News',
        //   type: 'link',
        //   link: '/newscenter',
        // },
        {
            index: "2-2",
            title: 'Blog (EN)',
            link: 'https://medium.com/@polarise',
        },
        {
            index: "2-2",
            title: 'Blog (ZH)',
            link: 'https://polarisecn.medium.com/',
        },
        {
            index: "2-2",
            title: 'Blog (KOR)',
            link: 'https://polarisekorea.medium.com/',
        },
        {
            index: "2-2",
            title: 'Blog (VN)',
            link: 'https://polarisevn.medium.com/',
        },
        //   {
        //     index: "2-3",
        //     title: 'Docs',
        //     link: 'https://pawnfi.gitbook.io/docs/',
        //   },
        ]
    },
    {
      title: t('nav.Dev'),
      label: "developer",
      index: "4",
      child: [
        // {
        //   index: "4-1",
        //   title: 'Github',
        //   link: 'https://github.com/PawnFi',
        // },
        {
          index: "4-2",
          title: 'SDK (TBU)',
          link: ''
        },
        {
            index: "4-3",
            title: 'Doc',
            type: 'outLink',
            link: 'https://docs.polarise.org/'
        }
      ]
    },
    {
        title: 'Priority Testers 🔥',
        label: "Priority Testers",
        index: "4",
        type: 'action',
        action: ()=>{setAirdropModal(true)},
        child: []
    },
    // {
    //     title: 'ERC-1000',
    //     label: "ERC-1000",
    //     index: "5",
    //     link: '/',
    //     child: []
    // },
    // {
    //   title: 'Collections',
    //   label: "collections",
    //   index: "5",
    //   link: '/collections',
    //   type: 'link',
    //   child: []
    // },
    // {
    //     title: 'Ape Stake',
    //     label: "Ape Stake",
    //     index: "4",
    //     child: [
    //       {
    //         index: "4-1",
    //         title: '$APE Pool',
    //         link: 'https://github.com/PawnFi',
    //       },
    //       {
    //         index: "4-2",
    //         title: 'NFT Pool',
    //         link: ''
    //       }
    //     ]
    // },
  
  ]

  return (
        <div className='official-top flex align_center justify_center'>
                <div className='top-nav flex align_center justify_between'>
                        <Link to='/'>
                            <div className='theme-logo cursor'>
                                <i className='iconfont icon-icon-logo text-white'></i>
                            </div>
                        </Link>
                        <div className='flex align_center h100'>
                               
                                <div className='text-1 nav-links flex'>
                                        {
                                            headerNav.map((val:navItem, index:number) => (               
                                                val.child.length ?                                
                                                <div key={index} className={`nav-link pa_4 cursor align_center flex`}>
                                                        <div className='link-tit'>{val.title}</div>
                                                        {
                                                            val.child.length > 0 &&
                                                            <i className='iconfont icon-icon-arrow2 ml_2'></i>
                                                        }
                                                        {
                                                            val.child.length > 0 &&
                                                            <div className={`link-child-navs flex column align_center br_3 ${val.title}`}>
                                                                    {
                                                                        val.child.map((item,index)=>(
                                                                            item.link?
                                                                                item.type==='link'?
                                                                                <Link to={item.link} className='link-child-nav py_4' key={item.title}>
                                                                                        <div>
                                                                                                {item.title}
                                                                                        </div>
                                                                                </Link>
                                                                                :
                                                                                <a href={item.link} key={item.title} className='link-child-nav py_4' rel="noreferrer" target='_blank'>
                                                                                        {item.title}
                                                                                </a>
                                                                            :
                                                                                <div key={index} className='link-child-nav py_4'>
                                                                                {item.title}
                                                                                </div>


                                                                        ))
                                                                    }
                                                            </div>
                                                            
                                                        }
                                                </div>
                                                :
                                                val.link?
                                                val.type==='link'?
                                                <Link to={val.link || '/'} key={val.link}>
                                                        <div key={index} className={`nav-link pa_4 cursor align_center flex ${pathname===val.link&&'text-white fw_m'}`}>
                                                                <div className='link-tit'>{val.title}</div>
                                                        </div>
                                                </Link>
                                                :
                                                <a href={val.link} key={index} className={`nav-link pa_4 cursor align_center flex`} target='_blank' rel="noreferrer">
                                                        <div className='link-tit'>{val.title}</div>
                                                </a>
                                                :
                                                <div className='link-tit flex align_center cursor white_space_nowrap' onClick={()=>val.action&&val.action()} key={index}>
                                                    {val.title} 
                                                </div>
                                            ))
                                        }
                                </div>
                        </div>
                        <div>
                            <Popover content={'Our app is being updated. Please stay tuned for the upcoming launch.'}>
                                <div 
                                className='text-white bg-main fw_600 right-btn cursor'
                                // onClick={()=>{
                                //     window.open(`https://app.pawnfi.com`)
                                // }}
                                >Launch App</div>
                            </Popover>
                            <i 
                            className={`iconfont icon-icon-${!mobileNavsDrawer?"menu":"delete fs_3 fw_b"} text-white cursor`}
                            onClick={()=>{setMobileNavsDrawer(!mobileNavsDrawer)}}
                            />
                        </div>
                </div>
                <MobileNavsDrawer navlist={headerNav} open={mobileNavsDrawer} onCancel={()=>{setMobileNavsDrawer(false)}}/>
                <AirdropModal visible={airdropModal} onCancel={()=>{setAirdropModal(false)}}/>
        </div>
  )
}
interface MobileNavsDrawerProps {
    open:boolean,
    onCancel:()=>void,
    navlist:navItem[]
}
const MobileNavsDrawer:React.FC<MobileNavsDrawerProps> = ({open,onCancel,navlist}) => {
    const navRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()
    const [activeNav, setActiveNav] = useState('')
    const openMainNav = (item:navItem) => {
        if(item.type==='action'){
            item.action&&item.action()
        }
        if(item.child.length>0){
            if(activeNav===item.label)return setActiveNav('')
            setActiveNav(item.label)
        }else{
            navigate(String(item.link))
            onCancel()
        }
    }
    const openChildNav = (item:any) => {
        if(item.type==='link'){
            navigate(item.link)
        }else{
            if(!item.link)return
            window.open(item.link)
        }
        onCancel()

    }
    return (
        <Drawer
        width={200}
        open={open}
        placement='top'
        className='mobile-navs-drawer'
        onClose={onCancel}
        height={500}
        >
            <div className='flex column text-1 mobile-navs' ref={navRef}>
                    {
                        navlist.map((item,idx)=>(
                            <div className='mobile-nav flex column' key={idx} onClick={()=>{openMainNav(item)}}>
                                <div className={`flex align_center ${activeNav===item.label&&'text-white fw_b'}`}>
                                        <div>{item.title}</div>
                                        {item.child.length>0 && <i className={`iconfont icon-icon-arrow2 ml_3 fs_1 ${activeNav===item.label&&'active'}`}></i>}
                                </div>
                                {
                                    <div className={`mobile-nav-childs flex column pl_4 mt_4 ${activeNav!==item.label && 'notExpand'}`}>
                                            {
                                                item.child.map((child,index)=>(
                                                    <div key={index} className='mobile-nav-child' onClick={()=>openChildNav(child)}>{child.title}</div>
                                                )
                                                )
                                            }
                                    </div>
                            
                                }
                            </div>
                        ))
                    }
            </div>
        </Drawer>
    )
}
interface AirdropModalProps {
    visible:boolean,
    onCancel:()=>void,
}
const AirdropModal:React.FC<AirdropModalProps> = ({visible,onCancel}) => {
  const rect = useViewport()

    return (
        <Modal 
            title={<div className='w100 flex justify_center fs_4 fw_b text-white mb_4'>Priority Testers</div>}
            open={visible}
            onCancel={onCancel}
            width={448} 
            centered
            footer={null}
            closeIcon={
                <i className='iconfont icon-icon-delete text-white fs_3'></i>
            }
            className='email-modal airdrop_modal'
        >
            <div className='flex column align_center text-white'>
                <img src="/images/airdrop_icon.png" alt="" />
                <div className='my_4 fs_2 fw_m text-1'>
                    <p className='px_7'>
                        Get early access to our ERC-1000 testnet version and enter the Polarfighter whitelist prize pool, along with the possibility of receiving future multiple airdrops!
                        Steps to participate:
                    </p>
                    <ul className='list px_8'>
                        <li>
                            Click the button below to enter the test webpage;
                        </li>
                        <li>
                        Change to Sepolia testnet and click the faucet in the top right corner to receive ETH test tokens;
                        </li>
                        <li>
                        Mint ERC-1000 test tokens;
                        </li>
                        <li>
                        Experience all the features of flash trade, leverage, and consign;
                        </li>
                        <li>
                        Stay tuned for our upcoming announcements!
                        </li>
                    </ul>
                </div>
                <div className='flex align_center text-white fw_b cursor bg-main py_3 px_6 br_4' onClick={()=>window.open('https://erc1000.polarise.org/')}>
                    Go
                </div>
            </div>
        </Modal> 
    )
}
export default Header